// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-buttonmaash-js": () => import("/opt/build/repo/src/pages/buttonmaash.js" /* webpackChunkName: "component---src-pages-buttonmaash-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-doublenightmode-js": () => import("/opt/build/repo/src/pages/doublenightmode.js" /* webpackChunkName: "component---src-pages-doublenightmode-js" */),
  "component---src-pages-hockeycompass-js": () => import("/opt/build/repo/src/pages/hockeycompass.js" /* webpackChunkName: "component---src-pages-hockeycompass-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orderr-js": () => import("/opt/build/repo/src/pages/orderr.js" /* webpackChunkName: "component---src-pages-orderr-js" */),
  "component---src-pages-phrasemag-js": () => import("/opt/build/repo/src/pages/phrasemag.js" /* webpackChunkName: "component---src-pages-phrasemag-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-subtrack-js": () => import("/opt/build/repo/src/pages/subtrack.js" /* webpackChunkName: "component---src-pages-subtrack-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

